import { CertificateType } from '@src/types/graphql-types'
import { messages as pdpMessages } from '@src/routes/ProductDetail/messages'
import { Message, messages as utilsMessages } from '@utils/messages'

export interface InstructionsSection {
  heading?: Message
  bodyCopy?: Message
  listItems?: Message[]
  link?: boolean
}

export type Instructions = Record<CertificateType, InstructionsSection[]>

const sigmaProducts: InstructionsSection = {
  heading: utilsMessages.SIGMA_PRODUCTS,
  listItems: [pdpMessages.FIND_LOT_NUMBER_SIGMA_1],
}

const aldrichProducts: InstructionsSection = {
  heading: utilsMessages.ALDRICH_PRODUCTS,
  listItems: [
    pdpMessages.FIND_LOT_NUMBER_ALDRICH_1,
    pdpMessages.FIND_LOT_NUMBER_ALDRICH_2,
    pdpMessages.FIND_LOT_NUMBER_ALDRICH_3,
  ],
}

const milliporeProducts: InstructionsSection = {
  heading: pdpMessages.FIND_LOT_NUMBER_MILLIPORE,
  listItems: [
    pdpMessages.FIND_LOT_NUMBER_MILLIPORE_1,
    pdpMessages.FIND_LOT_NUMBER_MILLIPORE_2,
  ],
}

export const pdpInstructions: Instructions = {
  [CertificateType.Coa]: [sigmaProducts, aldrichProducts, milliporeProducts],
  [CertificateType.Coo]: [sigmaProducts, aldrichProducts, milliporeProducts],
  [CertificateType.Coq]: [sigmaProducts, aldrichProducts, milliporeProducts],
}

const dialogOverview: InstructionsSection = {
  bodyCopy: {
    id: 'HOW_TO_ENTER_COO_COA_INSTRUCTIONS',
    defaultMessage:
      "Lot and Batch Numbers can be found on a product's label following the words 'Lot' or 'Batch'.",
  },
}

const dialogAldrichProducts: InstructionsSection = {
  heading: utilsMessages.ALDRICH_PRODUCTS,
  listItems: [
    utilsMessages.ENTER_WITHOUT_FIRST_TWO_LETTERS,
    utilsMessages.ENTER_WITHOUT_NUMERIC_FILLING_CODE,
    utilsMessages.ENTER_WITHOUT_ALPHA_NUMERIC_FILLING_CODE,
  ],
}

const dialogSigmaProducts: InstructionsSection = {
  heading: utilsMessages.SIGMA_PRODUCTS,
  listItems: [utilsMessages.SIGMA_PRODUCTS_INSTRUCTIONS],
}

const dialogMilliporeProducts: InstructionsSection = {
  heading: utilsMessages.MILLIPORE_PRODUCTS,
  bodyCopy: utilsMessages.MILLIPORE_PRODUCTS_INSTRUCTIONS,
  link: true,
}

export const dialogInstructions: Instructions = {
  [CertificateType.Coa]: [dialogOverview, dialogAldrichProducts],
  [CertificateType.Coo]: [
    dialogOverview,
    dialogSigmaProducts,
    dialogAldrichProducts,
  ],
  [CertificateType.Coq]: [dialogOverview, dialogMilliporeProducts],
}
