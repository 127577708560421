import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Typography,
  Theme,
  Divider,
  useMediaQuery,
  Button,
  Link as MUILink,
} from '@material-ui/core'
import clsx from 'clsx'

import { Link } from '@src/components/Link'
import { support } from '@src/routes'
import messages from '@utils/messages'
import vrStyles from '@src/styles/utils/vrStyles'
import { CertificateType } from '@src/types/graphql-types'
import { messages as pdpMessages } from '@src/routes/ProductDetail/messages'

export const useStyles = makeStyles((theme: Theme) => {
  const { vr2, vr4 } = vrStyles

  return {
    root: {
      backgroundColor: theme.palette.background.grey,
      marginTop: theme.spacing(6),
      padding: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4, 8),
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    divider: {
      backgroundColor: theme.palette.grey[400],
      margin: theme.spacing(6, 0),
    },
    vr2,
    vr4,
  }
})

interface NotFoundSectionProps {
  type: CertificateType
  isDialog?: boolean
}

const NotFoundSection: React.FC<NotFoundSectionProps> = ({
  type,
  isDialog,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')) === true
  const bodyCopySize = isDialog ? 'body1' : 'body2'
  const headingSize = isDialog ? 'h3' : 'body1'

  return (
    <>
      {!isDialog && (
        <div className={classes.root}>
          {!isDesktop && (
            <Typography
              component="h4"
              variant={headingSize}
              className={clsx(classes.bold, classes.vr2)}
            >
              <FormattedMessage {...pdpMessages.CANT_FIND_IT} />
            </Typography>
          )}
          <Typography
            component="p"
            variant={bodyCopySize}
            className={classes.vr2}
          >
            <FormattedMessage
              {...pdpMessages.CERT_NOT_FOUND}
              values={{
                certType: type,
              }}
            />
          </Typography>
          {!isDesktop && <Divider className={classes.divider} />}
          {!isDesktop && (
            <Button
              size="large"
              variant="outlined"
              color="primary"
              fullWidth
              data-testid=""
              href={support.customerSupport()}
            >
              <FormattedMessage
                {...pdpMessages.CERT_REQUEST}
                values={{
                  certType: type,
                }}
              />
            </Button>
          )}
          {isDesktop && (
            <Link href={support.customerSupport()} passHref>
              <MUILink variant={bodyCopySize} className={classes.bold}>
                <FormattedMessage
                  {...pdpMessages.CERT_REQUEST}
                  values={{
                    certType: type,
                  }}
                />
              </MUILink>
            </Link>
          )}
        </div>
      )}
      {isDialog && (
        <>
          <Typography
            component="h4"
            variant={headingSize}
            className={classes.vr2}
          >
            <FormattedMessage {...messages[`${type}_NOT_FOUND`]} />
          </Typography>
          <Typography variant={bodyCopySize}>
            {type === CertificateType.Coa && (
              <FormattedMessage
                id="COO_NOT_FOUND_INSTRUCTIONS"
                defaultMessage="In some cases, a COO may not be available online. If your search was unable to find the COO you can {requestACooLink}. In many cases a COO can be faxed to you upon submission of this form."
                values={{
                  requestACoaLink: (
                    <Link href={support.customerSupport()}>
                      <a>
                        <FormattedMessage
                          id="REQUEST_A_COA"
                          defaultMessage="request a COA"
                        />
                      </a>
                    </Link>
                  ),
                }}
              />
            )}
            {type === CertificateType.Coo && (
              <FormattedMessage
                id="COO_NOT_FOUND_INSTRUCTIONS"
                defaultMessage="In some cases, a COO may not be available online. If your search was unable to find the COO you can {requestACooLink}. In many cases a COO can be faxed to you upon submission of this form."
                values={{
                  requestACooLink: (
                    <Link href={support.customerSupport()}>
                      <a>
                        <FormattedMessage {...messages.REQUEST_A_COO} />
                      </a>
                    </Link>
                  ),
                }}
              />
            )}
            {type === CertificateType.Coq && (
              <FormattedMessage {...messages.COQ_NOT_FOUND_INSTRUCTIONS} />
            )}
          </Typography>
        </>
      )}
    </>
  )
}

export default NotFoundSection
