// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CertificateOfAnalysisQueryVariables = Types.Exact<{
  productNumber: Types.Scalars['String'];
  lotNumber: Types.Scalars['String'];
  brand?: Types.InputMaybe<Types.Scalars['String']>;
  count?: Types.InputMaybe<Types.Scalars['Int']>;
  sendLotNumber?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CertificateOfAnalysisQuery = { __typename?: 'Query', getCertificateOfAnalysis: { __typename?: 'CertificateText', brand: string, lotNumber: string, signatureUrl: string, owner?: string | null, unit?: string | null, source?: string | null, testSpecifications?: Array<{ __typename?: 'TestSpec', name: string, value?: string | null, result?: string | null }> | null, product?: { __typename?: 'Product', name: string, description?: string | null, productNumber: string, molecularWeight?: string | null, linearFormula?: string | null, casNumber?: string | null, brand: { __typename?: 'Brand', key: string, name?: string | null, color?: string | null, logo?: { __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string } | null } } | null } | { __typename?: 'CertificateUrl', url: string, streamData?: string | null } };

export type CertificateProductFragment = { __typename?: 'Product', name: string, description?: string | null, productNumber: string, molecularWeight?: string | null, linearFormula?: string | null, casNumber?: string | null, brand: { __typename?: 'Brand', key: string, name?: string | null, color?: string | null, logo?: { __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string } | null } };

export type TestSpecificationsFragment = { __typename?: 'TestSpec', name: string, value?: string | null, result?: string | null };

export const CertificateProductFragmentDoc = gql`
    fragment CertificateProduct on Product {
  name
  description
  brand {
    key
    name
    color
    logo {
      altText
      smallUrl
      mediumUrl
      largeUrl
    }
  }
  productNumber
  molecularWeight
  linearFormula
  casNumber
}
    `;
export const TestSpecificationsFragmentDoc = gql`
    fragment TestSpecifications on TestSpec {
  name
  value
  result
}
    `;
export const CertificateOfAnalysisDocument = gql`
    query CertificateOfAnalysis($productNumber: String!, $lotNumber: String!, $brand: String, $count: Int, $sendLotNumber: Boolean) {
  getCertificateOfAnalysis(input: {productNumber: $productNumber, lotNumber: $lotNumber, brand: $brand, count: $count, sendLotNumber: $sendLotNumber}) {
    ... on CertificateUrl {
      url
      streamData
    }
    ... on CertificateText {
      brand
      lotNumber
      signatureUrl
      testSpecifications {
        ...TestSpecifications
      }
      product {
        ...CertificateProduct
      }
      owner
      unit
      source
    }
  }
}
    ${TestSpecificationsFragmentDoc}
${CertificateProductFragmentDoc}`;
export type CertificateOfAnalysisComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CertificateOfAnalysisQuery, CertificateOfAnalysisQueryVariables>, 'query'> & ({ variables: CertificateOfAnalysisQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CertificateOfAnalysisComponent = (props: CertificateOfAnalysisComponentProps) => (
      <ApolloReactComponents.Query<CertificateOfAnalysisQuery, CertificateOfAnalysisQueryVariables> query={CertificateOfAnalysisDocument} {...props} />
    );
    

/**
 * __useCertificateOfAnalysisQuery__
 *
 * To run a query within a React component, call `useCertificateOfAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificateOfAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificateOfAnalysisQuery({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *      lotNumber: // value for 'lotNumber'
 *      brand: // value for 'brand'
 *      count: // value for 'count'
 *      sendLotNumber: // value for 'sendLotNumber'
 *   },
 * });
 */
export function useCertificateOfAnalysisQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CertificateOfAnalysisQuery, CertificateOfAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CertificateOfAnalysisQuery, CertificateOfAnalysisQueryVariables>(CertificateOfAnalysisDocument, options);
      }
export function useCertificateOfAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CertificateOfAnalysisQuery, CertificateOfAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CertificateOfAnalysisQuery, CertificateOfAnalysisQueryVariables>(CertificateOfAnalysisDocument, options);
        }
export type CertificateOfAnalysisQueryHookResult = ReturnType<typeof useCertificateOfAnalysisQuery>;
export type CertificateOfAnalysisLazyQueryHookResult = ReturnType<typeof useCertificateOfAnalysisLazyQuery>;
export type CertificateOfAnalysisQueryResult = ApolloReactCommon.QueryResult<CertificateOfAnalysisQuery, CertificateOfAnalysisQueryVariables>;