import { defineMessages } from 'react-intl'

export interface howToMessage {
  id: string
  defaultMessage: string
}

export const howToMessages = defineMessages({
  HOW_TO_FIND_PRODUCT_NUMBER: {
    id: 'HOW_TO_FIND_PRODUCT_NUMBER',
    defaultMessage: 'How to Find the Product Number',
  },
  HOW_TO_FIND_LOT_NUMBER: {
    id: 'HOW_TO_FIND_LOT_NUMBER',
    defaultMessage: 'How to Find a Lot/Batch Number for {formType}',
  },
  HOW_TO_ENTER_PRODUCT_NUMBER: {
    id: 'HOW_TO_ENTER_PRODUCT_NUMBER',
    defaultMessage: 'How to Enter Product Number',
  },
  HOW_TO_ENTER_LOT_NUMBER: {
    id: 'HOW_TO_ENTER_LOT_NUMBER',
    defaultMessage: 'How to enter Lot Number',
  },
  HOW_TO_ENTER_A_PRODUCT_NUMBER: {
    id: 'HOW_TO_ENTER_A_PRODUCT_NUMBER',
    defaultMessage: 'How to enter a Product Number?',
  },
  HOW_TO_ENTER_PRODUCT_NUMBER_EXAMPLE: {
    id: 'HOW_TO_ENTER_PRODUCT_NUMBER_EXAMPLE',
    defaultMessage:
      'Product numbers are combined with Pack Sizes/Quantity when displayed on the website (example: T1503-25G). Please make sure you enter ',
  },
  HOW_TO_ENTER_PRODUCT_NUMBER_INSTRUCTIONS_TEXT: {
    id: 'HOW_TO_ENTER_PRODUCT_NUMBER_INSTRUCTIONS_TEXT',
    defaultMessage: 'ONLY the product number in the Product Number field',
  },
  HOW_TO_ENTER_PRODUCT_NUMBER_TEXT: {
    id: 'HOW_TO_ENTER_PRODUCT_NUMBER_TEXT',
    defaultMessage: 'Below are other examples. For assistance contact',
  },
  HOW_TO_ENTER_COA: {
    id: 'HOW_TO_ENTER_COA',
    defaultMessage: 'How to enter a Lot Number for COA?',
  },
  HOW_TO_ENTER_COO: {
    id: 'HOW_TO_ENTER_COO',
    defaultMessage: 'How to enter a Lot Number for COO?',
  },
  HOW_TO_ENTER_COO_COA_INSTRUCTIONS: {
    id: 'HOW_TO_ENTER_COO_INSTRUCTIONS',
    defaultMessage:
      "Lot and Batch Numbers can be found on a product's label following the words 'Lot' or 'Batch'.",
  },
  HOW_TO_ENTER_COQ: {
    id: 'HOW_TO_ENTER_COQ',
    defaultMessage: 'How to enter a Lot Number for COQ?',
  },
  //Message returned as endpoint error. Do not modify or delete key.
  HOW_TO_UNKNOWN: {
    id: 'HOW_TO_UNKNOWN',
    defaultMessage:
      "Unable to load 'how to' instructions. Please contact support if you need additional help.",
  },
})

export default howToMessages
