// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PdfDownloadQueryVariables = Types.Exact<{
  input: Types.PdfDownloadInput;
}>;


export type PdfDownloadQuery = { __typename?: 'Query', pdfDownload?: string | null };


export const PdfDownloadDocument = gql`
    query pdfDownload($input: PdfDownloadInput!) {
  pdfDownload(input: $input)
}
    `;
export type PdfDownloadComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PdfDownloadQuery, PdfDownloadQueryVariables>, 'query'> & ({ variables: PdfDownloadQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PdfDownloadComponent = (props: PdfDownloadComponentProps) => (
      <ApolloReactComponents.Query<PdfDownloadQuery, PdfDownloadQueryVariables> query={PdfDownloadDocument} {...props} />
    );
    

/**
 * __usePdfDownloadQuery__
 *
 * To run a query within a React component, call `usePdfDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfDownloadQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePdfDownloadQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PdfDownloadQuery, PdfDownloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PdfDownloadQuery, PdfDownloadQueryVariables>(PdfDownloadDocument, options);
      }
export function usePdfDownloadLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PdfDownloadQuery, PdfDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PdfDownloadQuery, PdfDownloadQueryVariables>(PdfDownloadDocument, options);
        }
export type PdfDownloadQueryHookResult = ReturnType<typeof usePdfDownloadQuery>;
export type PdfDownloadLazyQueryHookResult = ReturnType<typeof usePdfDownloadLazyQuery>;
export type PdfDownloadQueryResult = ApolloReactCommon.QueryResult<PdfDownloadQuery, PdfDownloadQueryVariables>;