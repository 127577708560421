import { useCurrentUser } from '@utils/useCurrentUser'

export enum Abilities {
  canViewInventory = 'canViewInventory',
  canAddToCart = 'canAddToCart',
  canViewOnlineOrder = 'canViewOnlineOrder',
  canViewOnlineQuote = 'canViewOnlineQuote',
}

/**
 *
 * A hook that provides authentication and authorization info for the current user to components.
 *
 * e.g.
 *    const { authorized, anonymous } = useAbility(Abilities.canViewOnlineOrder)
 *    if (authorized) { ... do something }
 *    if (anonymous) { ... do something else }
 *
 * You can also do more complex permissions with multiple hooks:
 *
 *    const { authorized: canAddToCart } = useAbility(Abilities.canAddToCart)
 *    const { authorized: canViewOnlineOrder } = useAbility(Abilities.canViewOnlineOrder)
 *    if (canViewOnlineOrder && !canAddToCart) { ... do something }
 *
 **/
export const useAbility = (ability: Abilities) => {
  const { currentUser, currentUserLoading } = useCurrentUser()
  const defaultAuthState = { authorized: false, anonymous: false }

  if (currentUserLoading) return defaultAuthState
  if (!currentUser) return { ...defaultAuthState, anonymous: true }

  return Boolean(currentUser.abilities[ability])
    ? { ...defaultAuthState, authorized: true }
    : defaultAuthState
}
