// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SdsCertificateSearchQueryVariables = Types.Exact<{
  productNumber: Types.Scalars['String'];
  brand?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SdsCertificateSearchQuery = { __typename?: 'Query', getSdsCertificateSearch?: { __typename?: 'SdsSearchResponse', locale: string, region: string, productNumber: string, brands: Array<string | null>, sds: Array<{ __typename?: 'SdsData', brand: string, languages: { __typename?: 'SDSLanuguageOptions', primaryLanguage: string, altLanguages: Array<string> } } | null> } | null };


export const SdsCertificateSearchDocument = gql`
    query SdsCertificateSearch($productNumber: String!, $brand: String) {
  getSdsCertificateSearch(input: {productNumber: $productNumber, brand: $brand}) {
    locale
    region
    productNumber
    sds {
      languages {
        primaryLanguage
        altLanguages
      }
      brand
    }
    brands
  }
}
    `;
export type SdsCertificateSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SdsCertificateSearchQuery, SdsCertificateSearchQueryVariables>, 'query'> & ({ variables: SdsCertificateSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SdsCertificateSearchComponent = (props: SdsCertificateSearchComponentProps) => (
      <ApolloReactComponents.Query<SdsCertificateSearchQuery, SdsCertificateSearchQueryVariables> query={SdsCertificateSearchDocument} {...props} />
    );
    

/**
 * __useSdsCertificateSearchQuery__
 *
 * To run a query within a React component, call `useSdsCertificateSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSdsCertificateSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSdsCertificateSearchQuery({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useSdsCertificateSearchQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SdsCertificateSearchQuery, SdsCertificateSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SdsCertificateSearchQuery, SdsCertificateSearchQueryVariables>(SdsCertificateSearchDocument, options);
      }
export function useSdsCertificateSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SdsCertificateSearchQuery, SdsCertificateSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SdsCertificateSearchQuery, SdsCertificateSearchQueryVariables>(SdsCertificateSearchDocument, options);
        }
export type SdsCertificateSearchQueryHookResult = ReturnType<typeof useSdsCertificateSearchQuery>;
export type SdsCertificateSearchLazyQueryHookResult = ReturnType<typeof useSdsCertificateSearchLazyQuery>;
export type SdsCertificateSearchQueryResult = ApolloReactCommon.QueryResult<SdsCertificateSearchQuery, SdsCertificateSearchQueryVariables>;