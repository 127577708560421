import Icon, { BaseIconProps } from './Icon'
function LockIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 15 20"
        {...props}
      >
        <path d="M12.69 7.48h-.17v-3c.09-1.18-.31-2.35-1.1-3.23A5.25 5.25 0 0 0 7.59 0c-4.93 0-4.95 3.33-5 4.42v3.06h-.28A2.29 2.29 0 0 0 0 9.76v8A2.297 2.297 0 0 0 2.31 20h10.38c1.27.01 2.3-1.01 2.31-2.28v-8a2.297 2.297 0 0 0-2.31-2.24M4.36 4.63v-.19c0-1.16 0-2.73 3.23-2.73.93-.09 1.86.18 2.6.76.46.56.67 1.28.6 2v3H4.36c-.01-.74-.01-2.11 0-2.84m8.91 13.09c0 .31-.26.57-.57.57H2.31a.576.576 0 0 1-.58-.56V9.72c0-.31.26-.57.57-.57h10.39c.31-.01.57.25.58.56v8.01" />
        <path d="M7.5 12c-.9-.06-1.67.62-1.73 1.52.01.48.25.92.65 1.19v.78c0 .6.48 1.08 1.08 1.08s1.08-.48 1.08-1.08v-.78c.4-.27.64-.71.65-1.19-.06-.9-.83-1.58-1.73-1.52" />
      </svg>
    </Icon>
  )
}
export default LockIcon
