import React, { useState } from 'react'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import {
  Divider,
  Box,
  Button,
  Popover,
  CircularProgress,
  useMediaQuery,
  ButtonProps,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import messages from '@utils/messages'
import { Link } from '@src/components/Link'
import { homeRoute, registerRoute, loginRoute, useRouter } from '@src/routes'
import { useCurrentUser } from '@utils/useCurrentUser'
import { Abilities, useAbility } from '@utils/useAbility'

export const useStyles = makeStyles((theme: Theme) => ({
  addToCartButton: {
    minWidth: theme.typography.pxToRem(136),
  },
  cannotAddToCartLink: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  loadingIcon: {
    position: 'absolute',
    top: '10%',
    left: '50%',
    marginLeft: '-20px',
    zIndex: 1,
  },
  addToCartLoading: {
    color: theme.palette.primary.main,
  },
  popoverContainer: {
    height: 'auto',
    minHeight: 'auto',
    width: '550px',
    border: '4px solid grey',
    position: 'relative',
    zIndex: 1,
  },
  popoverHeader: {
    width: '100%',
    height: '50px',
    padding: '5px 10px',
    backgroundColor: theme.palette.grey[50],
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: theme.typography.pxToRem(10),
    color: theme.palette.grey[700],
    cursor: 'pointer',
  },
  popoverBody: {
    width: '100%',
    height: '55%',
    padding: '10px 8px',
    margin: 'auto',
    fontSize: theme.typography.pxToRem(12),
  },
}))

export enum Variants {
  Contained = 'contained',
  Outlined = 'outlined',
}

export enum Colors {
  Primary = 'primary',
  Secondary = 'secondary',
}

interface AddToCartPopoverProps {
  popoverHeaderClass?: string
  popoverContainerClass?: string
  popoverBodyClass?: string
  hideCannotAddToCartPopover?: (e?) => void
  cannotAddToCartPopoverAnchor?: any
}

const AddToCartPopover: React.FC<AddToCartPopoverProps> = ({
  cannotAddToCartPopoverAnchor,
  popoverContainerClass,
  popoverHeaderClass,
  popoverBodyClass,
  hideCannotAddToCartPopover,
}) => {
  const classes = useStyles()
  return (
    <Popover
      anchorEl={cannotAddToCartPopoverAnchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      elevation={4}
      open
    >
      <div className={popoverContainerClass || classes.popoverContainer}>
        <div className={popoverHeaderClass || classes.popoverHeader}>
          <FormattedMessage
            id="CANNOT_ADD_TO_CART"
            defaultMessage="Cannot Add To Cart"
          />
          <ClearIcon
            aria-label="Close cannot add to cart details"
            onClick={hideCannotAddToCartPopover}
            onKeyPress={hideCannotAddToCartPopover}
            className={classes.closeIcon}
          />
        </div>
        <div className={popoverBodyClass || classes.popoverBody}>
          <FormattedMessage
            id="CANNOT_ADD_TO_CART_DETAILS"
            defaultMessage="Your profile is currently configured to allow ordering for configurable items such as oligos and peptides but not regular inventory items. Although you can access pricing and availability for these products, they cannot be added to your shopping cart."
          />
        </div>
      </div>
    </Popover>
  )
}

interface GuestShoppingListProps {
  addToCartButtonClass?: string
  variant?: Variants
  size?: ButtonProps['size']
}

const GuestShoppingList: React.FC<GuestShoppingListProps> = ({
  size,
  addToCartButtonClass,
  variant,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const router = useRouter()
  const isDesktop =
    useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true }) === true
  return (
    <Link
      href={`${registerRoute.linkProfile()}?returnUrl=${encodeURIComponent(
        router.asPath || homeRoute.index()
      )}`}
    >
      <Button
        size={size || (isDesktop ? 'medium' : 'large')}
        variant={variant}
        color="secondary"
        data-testid="link-profile-button"
        className={addToCartButtonClass || classes.addToCartButton}
      >
        <FormattedMessage {...messages.LINK_PROFILE} />
      </Button>
    </Link>
  )
}

const AddToCartLink = ({ size, variant, addToCartButtonClass }) => {
  const theme = useTheme()
  const router = useRouter()
  const isDesktop =
    useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true }) === true
  return (
    <Link
      href={`${loginRoute.index()}?redirect=${encodeURIComponent(
        router.asPath || homeRoute.index()
      )}`}
    >
      <Button
        size={size || (isDesktop ? 'medium' : 'large')}
        variant={variant}
        color="secondary"
        className={addToCartButtonClass}
      >
        <FormattedMessage {...messages.LOGIN_TO_ADD_TO_CART} />
      </Button>
    </Link>
  )
}

interface AddToCartActionButtonProps {
  dirty: boolean
  isSubmitting: boolean
  handleAddToCart?: (e?) => void
  addToCartButtonClass?: string
  variant?: Variants
  color?: Colors
  fullWidth?: boolean
  isAddToList?: boolean
  disabled?: boolean
  size?: ButtonProps['size']
}

const AddToCartActionButton: React.FC<AddToCartActionButtonProps> = ({
  size,
  variant,
  color,
  isSubmitting,
  fullWidth,
  handleAddToCart,
  addToCartButtonClass,
  dirty,
  disabled,
  isAddToList,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop =
    useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true }) === true
  return (
    <Button
      size={size || (isDesktop ? 'medium' : 'large')}
      type="submit"
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      disabled={isSubmitting || !dirty || disabled}
      onClick={handleAddToCart}
      className={addToCartButtonClass || classes.addToCartButton}
      data-testid="add-to-cart-button"
      id="add-to-cart-button"
    >
      {isSubmitting && (
        <CircularProgress
          size={30}
          className={clsx(classes.addToCartLoading, classes.loadingIcon)}
        />
      )}
      {isAddToList ? (
        <FormattedMessage
          id="REPLACE_PRODUCT"
          defaultMessage="Replace Product"
        />
      ) : (
        <FormattedMessage id="ADD_TO_CART" defaultMessage="Add to Cart" />
      )}
    </Button>
  )
}

interface AddToCartButtonProps {
  dirty: boolean
  isSubmitting: boolean
  canAddToCart: boolean
  hideAddToCartForPrepackItems: boolean
  handleAddToCart?: (e?) => void
  cannotAddToCartLinkClass?: string
  addToCartButtonClass?: string
  popoverHeaderClass?: string
  popoverContainerClass?: string
  popoverBodyClass?: string
  variant?: Variants
  color?: Colors
  fullWidth?: boolean
  isAddToList?: boolean
  disabled?: boolean
  size?: ButtonProps['size']
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({
  hideAddToCartForPrepackItems,
  canAddToCart,
  handleAddToCart,
  isSubmitting,
  dirty,
  cannotAddToCartLinkClass,
  addToCartButtonClass,
  popoverContainerClass,
  popoverHeaderClass,
  popoverBodyClass,
  variant = Variants.Contained,
  color = Colors.Secondary,
  fullWidth,
  isAddToList = false,
  disabled,
  size,
}) => {
  const [cannotAddToCartPopoverAnchor, setCannotAddToCartPopoverAnchor] =
    useState<HTMLSpanElement | null>(null)

  const showCannotAddToCartPopover = (
    e:
      | React.MouseEvent<EventTarget & HTMLSpanElement>
      | React.KeyboardEvent<EventTarget & HTMLSpanElement>
  ) => {
    e.preventDefault()
    setCannotAddToCartPopoverAnchor(e.currentTarget)
  }
  const hideCannotAddToCartPopover = () => setCannotAddToCartPopoverAnchor(null)
  const classes = useStyles()
  const { currentUser } = useCurrentUser()
  const { authorized } = useAbility(Abilities.canAddToCart)

  let guestShoppingType = ''

  if (
    authorized &&
    currentUser?.__typename === 'LoggedInUser' &&
    currentUser?.metadata?.guestShoppingType
  ) {
    guestShoppingType = currentUser?.metadata?.guestShoppingType
  }

  return (
    <>
      {cannotAddToCartPopoverAnchor && (
        <AddToCartPopover
          cannotAddToCartPopoverAnchor={cannotAddToCartPopoverAnchor}
          popoverContainerClass={popoverContainerClass}
          popoverHeaderClass={popoverHeaderClass}
          popoverBodyClass={popoverBodyClass}
          hideCannotAddToCartPopover={hideCannotAddToCartPopover}
        />
      )}

      {hideAddToCartForPrepackItems ? (
        <>
          <Divider light />
          <span
            className={cannotAddToCartLinkClass || classes.cannotAddToCartLink}
            onClick={showCannotAddToCartPopover}
            onKeyPress={showCannotAddToCartPopover}
            tabIndex={0}
            role="button"
          >
            <FormattedMessage
              id="CANNOT_ADD_TO_CART"
              defaultMessage="Cannot Add To Cart"
            />
            <Box marginLeft={1}>
              <InfoIcon fontSize="small" />
            </Box>
          </span>
        </>
      ) : canAddToCart ? (
        <AddToCartActionButton
          size={size}
          variant={variant}
          addToCartButtonClass={addToCartButtonClass}
          isAddToList={isAddToList}
          disabled={disabled}
          fullWidth={fullWidth}
          dirty={dirty}
          handleAddToCart={handleAddToCart}
          isSubmitting={isSubmitting}
          color={color}
        />
      ) : guestShoppingType.length > 0 ? (
        <GuestShoppingList
          size={size}
          variant={variant}
          addToCartButtonClass={addToCartButtonClass}
        />
      ) : (
        <AddToCartLink
          size={size}
          variant={variant}
          addToCartButtonClass={addToCartButtonClass}
        />
      )}
    </>
  )
}

export default AddToCartButton
