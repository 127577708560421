import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  DOCUMENTATION_TABS_LABEL: {
    id: 'DOCUMENTATION_TABS_LABEL',
    defaultMessage:
      'Search for product certificates by entering the products Lot/Batch Number.',
  },
  LOT_BATCH_NUMBER: {
    id: 'LOT_BATCH_NUMBER',
    defaultMessage: 'Lot/Batch Number',
  },
  CANT_FIND_IT: {
    id: 'CANT_FIND_IT',
    defaultMessage: 'Can’t find it?',
  },
  CERT_NOT_FOUND: {
    id: 'CERT_NOT_FOUND',
    defaultMessage:
      'In some cases, a {certType} may not be available online. If your search was unable to find the {certType} you can request one.',
  },
  CERT_REQUEST: {
    id: 'CERT_REQUEST',
    defaultMessage: 'Request a {certType}',
  },
  FIND_LOT_NUMBER_SIGMA_1: {
    id: 'FIND_LOT_NUMBER_SIGMA_1',
    defaultMessage:
      'Enter a “0” if only two numbers are in front of the letter e.g. 62K1064 - you will only find the COO if you enter 062K1064.',
  },
  FIND_LOT_NUMBER_ALDRICH_1: {
    id: 'FIND_LOT_NUMBER_ALDRICH_1',
    defaultMessage:
      'If you find a lot number such as TO09019TO - enter the lot number without the first two letters e.g. 09019TO.',
  },
  FIND_LOT_NUMBER_ALDRICH_2: {
    id: 'FIND_LOT_NUMBER_ALDRICH_2',
    defaultMessage:
      'If the lot number has a filling-code such as 05427ES-021 - enter the lot number without the filling-code -021 e.g. 05427ES.',
  },
  FIND_LOT_NUMBER_ALDRICH_3: {
    id: 'FIND_LOT_NUMBER_ALDRICH_3',
    defaultMessage:
      'If the lot number has a filling-code such as STBB0728K9 - enter the lot number STBB0728 without the filling-code K9.',
  },
  FIND_LOT_NUMBER_MILLIPORE: {
    id: 'FIND_LOT_NUMBER_MILLIPORE',
    defaultMessage: 'EMD Millipore',
  },
  FIND_LOT_NUMBER_MILLIPORE_1: {
    id: 'FIND_LOT_NUMBER_MILLIPORE_1',
    defaultMessage:
      'Lot and Batch Numbers can be found on a product’s label following the word.',
  },
  FIND_LOT_NUMBER_MILLIPORE_2: {
    id: 'FIND_LOT_NUMBER_MILLIPORE_2',
    defaultMessage:
      'Some lot numbers include an additional three characters that trail the primary lot number. If you have a lot of batch number such as this, do not include the three trailing characters when searching for a COA or COQ.',
  },
  SAMPLE_LOT_BATCH_NUMBERS_IMG_ALT_TEXT: {
    id: 'SAMPLE_LOT_BATCH_NUMBERS_IMG_ALT_TEXT',
    defaultMessage:
      'Product label examples that illustrate the locations of lot and batch numbers',
  },
})

export default messages
