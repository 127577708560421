// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AutoCompleteCertificateQueryVariables = Types.Exact<{
  brand?: Types.InputMaybe<Types.Scalars['String']>;
  lotNumber?: Types.InputMaybe<Types.Scalars['String']>;
  productNumber?: Types.InputMaybe<Types.Scalars['String']>;
  term: Types.Scalars['String'];
  type: Types.Scalars['String'];
}>;


export type AutoCompleteCertificateQuery = { __typename?: 'Query', getAutoCompleteCertificate?: { __typename?: 'AutoCompleteCertificateResponse', term: string, results?: Array<{ __typename?: 'AutoCompleteCertificateItem', value: string, count?: number | null }> | null } | null };


export const AutoCompleteCertificateDocument = gql`
    query AutoCompleteCertificate($brand: String, $lotNumber: String, $productNumber: String, $term: String!, $type: String!) {
  getAutoCompleteCertificate(input: {brand: $brand, lotNumber: $lotNumber, productNumber: $productNumber, term: $term, type: $type}) {
    term
    results {
      value
      count
    }
  }
}
    `;
export type AutoCompleteCertificateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AutoCompleteCertificateQuery, AutoCompleteCertificateQueryVariables>, 'query'> & ({ variables: AutoCompleteCertificateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AutoCompleteCertificateComponent = (props: AutoCompleteCertificateComponentProps) => (
      <ApolloReactComponents.Query<AutoCompleteCertificateQuery, AutoCompleteCertificateQueryVariables> query={AutoCompleteCertificateDocument} {...props} />
    );
    

/**
 * __useAutoCompleteCertificateQuery__
 *
 * To run a query within a React component, call `useAutoCompleteCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoCompleteCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoCompleteCertificateQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      lotNumber: // value for 'lotNumber'
 *      productNumber: // value for 'productNumber'
 *      term: // value for 'term'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAutoCompleteCertificateQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AutoCompleteCertificateQuery, AutoCompleteCertificateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AutoCompleteCertificateQuery, AutoCompleteCertificateQueryVariables>(AutoCompleteCertificateDocument, options);
      }
export function useAutoCompleteCertificateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AutoCompleteCertificateQuery, AutoCompleteCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AutoCompleteCertificateQuery, AutoCompleteCertificateQueryVariables>(AutoCompleteCertificateDocument, options);
        }
export type AutoCompleteCertificateQueryHookResult = ReturnType<typeof useAutoCompleteCertificateQuery>;
export type AutoCompleteCertificateLazyQueryHookResult = ReturnType<typeof useAutoCompleteCertificateLazyQuery>;
export type AutoCompleteCertificateQueryResult = ApolloReactCommon.QueryResult<AutoCompleteCertificateQuery, AutoCompleteCertificateQueryVariables>;