import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Theme, Link } from '@material-ui/core'
import clsx from 'clsx'

import vrStyles from '@src/styles/utils/vrStyles'
import { CertificateType } from '@src/types/graphql-types'
import {
  InstructionsSection,
  dialogInstructions,
  pdpInstructions,
} from './instructions'
import messages from '@src/utils/messages'
import { support } from '@src/routes'

export const useStyles = makeStyles((theme: Theme) => {
  const { vr1, vr4 } = vrStyles

  return {
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    list: {
      paddingLeft: theme.spacing(4),
      '& li:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    listLarge: {
      margin: theme.spacing(0, 0, 0),
      paddingLeft: theme.spacing(5),
      '& li:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
    vr1,
    vr4,
  }
})

const eventLabelForCustomerSupportLink = 'customer support link'

interface LotNumberInstructionsSectionProps {
  section: InstructionsSection
  isDialog?: boolean
  isAccordion?: boolean
  customerSupportLinkClicked?: Function
}

const LotNumberInstructionsSection: React.FC<
  LotNumberInstructionsSectionProps
> = ({ section, isDialog, isAccordion, customerSupportLinkClicked }) => {
  const classes = useStyles()
  const { heading, bodyCopy, listItems, link } = section
  const bodyCopySize = isAccordion ? 'body2' : isDialog ? 'body1' : 'body2'
  const headingSize = isAccordion ? 'body2' : isDialog ? 'h3' : 'body1'

  return (
    <div className={classes.vr4}>
      {heading && (
        <Typography
          component="h4"
          variant={headingSize}
          className={clsx(
            classes.vr1,
            !isDialog && classes.bold,
            isAccordion && classes.bold
          )}
        >
          <FormattedMessage {...heading} />
        </Typography>
      )}
      {bodyCopy && link && (
        <ul
          className={clsx(
            !isDialog && classes.list,
            (isDialog || isAccordion) && classes.listLarge
          )}
        >
          <li>
            <Typography variant={bodyCopySize}>
              <FormattedMessage {...bodyCopy} />

              <Link
                onClick={() =>
                  customerSupportLinkClicked
                    ? customerSupportLinkClicked(
                        eventLabelForCustomerSupportLink,
                        {
                          action:
                            'contact customer support - how to find a lot/batch number for coq',
                          component: 'right rail',
                          elementType: 'link',
                          elementText: 'customer support',
                          linkUrl: support.customerSupport(),
                        }
                      )
                    : null
                }
                href={support.customerSupport()}
              >
                <FormattedMessage {...messages.CUSTOMER_SUPPORT} />
              </Link>
            </Typography>
          </li>
        </ul>
      )}
      {bodyCopy && !link && (
        <Typography variant={bodyCopySize}>
          <FormattedMessage {...bodyCopy} />
        </Typography>
      )}
      {listItems && (
        <ul
          className={clsx(
            !isDialog && classes.list,
            (isDialog || isAccordion) && classes.listLarge
          )}
        >
          {listItems.map((item, i) => (
            <li key={i}>
              <Typography variant={bodyCopySize}>
                <FormattedMessage {...item} />
              </Typography>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

interface LotNumberInstructionsProps {
  type: CertificateType
  isDialog?: boolean
  isAccordion?: boolean
  customerSupportLinkClicked?: Function
}

const LotNumberInstructions: React.FC<LotNumberInstructionsProps> = ({
  type,
  isDialog,
  isAccordion,
  customerSupportLinkClicked,
}) => (
  <>
    {!isDialog &&
      pdpInstructions[type].map((section: InstructionsSection, i: number) => (
        <LotNumberInstructionsSection
          key={i}
          section={section}
          isDialog={isDialog}
          isAccordion={isAccordion}
          customerSupportLinkClicked={customerSupportLinkClicked}
        />
      ))}
    {isDialog &&
      dialogInstructions[type].map(
        (section: InstructionsSection, i: number) => (
          <LotNumberInstructionsSection
            key={i}
            section={section}
            isDialog={isDialog}
            isAccordion={isAccordion}
            customerSupportLinkClicked={customerSupportLinkClicked}
          />
        )
      )}
  </>
)

export default LotNumberInstructions
