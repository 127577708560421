import React from 'react'
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  ButtonBase,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      borderRadius: 0,
    },
    dialogHeader: {
      fontSize: theme.typography.pxToRem(28),
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(8, 8, 0, 8),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    closeIcon: {
      fontSize: theme.typography.pxToRem(30),
    },
    dialogBody: {
      padding: theme.spacing(4, 8),
      fontSize: theme.typography.pxToRem(16),
    },
  }
})

const Modal = ({ open, setModalOpen, bodyContent, headerContent }) => {
  const classes = useStyles()

  return (
    <Dialog open={open} maxWidth="md" classes={{ paper: classes.paper }}>
      <Grid container>
        <DialogTitle disableTypography classes={{ root: classes.dialogHeader }}>
          {headerContent}
          <ButtonBase onClick={() => setModalOpen(false)}>
            <CloseIcon classes={{ root: classes.closeIcon }} />
          </ButtonBase>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogBody }}>
          <Grid container>{bodyContent}</Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  )
}

export default Modal
