// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CertificateOfOriginQueryVariables = Types.Exact<{
  productNumber: Types.Scalars['String'];
  lotNumber: Types.Scalars['String'];
  brand?: Types.InputMaybe<Types.Scalars['String']>;
  documentType?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CertificateOfOriginQuery = { __typename?: 'Query', getCertificateOfOrigin: { __typename?: 'CertificateText', lotNumber: string, signatureUrl: string, testSpecifications?: Array<{ __typename?: 'TestSpec', name: string, value?: string | null, result?: string | null }> | null, product?: { __typename?: 'Product', name: string, description?: string | null, productNumber: string, molecularWeight?: string | null, linearFormula?: string | null, casNumber?: string | null, brand: { __typename?: 'Brand', key: string, name?: string | null, color?: string | null, logo?: { __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string } | null } } | null } | { __typename?: 'CertificateUrl', url: string } };

export type CertificateOfOriginProductFragment = { __typename?: 'Product', name: string, description?: string | null, productNumber: string, molecularWeight?: string | null, linearFormula?: string | null, casNumber?: string | null, brand: { __typename?: 'Brand', key: string, name?: string | null, color?: string | null, logo?: { __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string } | null } };

export type CertificateOfOriginTestSpecificationsFragment = { __typename?: 'TestSpec', name: string, value?: string | null, result?: string | null };

export const CertificateOfOriginProductFragmentDoc = gql`
    fragment CertificateOfOriginProduct on Product {
  name
  description
  brand {
    key
    name
    color
    logo {
      altText
      smallUrl
      mediumUrl
      largeUrl
    }
  }
  productNumber
  molecularWeight
  linearFormula
  casNumber
}
    `;
export const CertificateOfOriginTestSpecificationsFragmentDoc = gql`
    fragment CertificateOfOriginTestSpecifications on TestSpec {
  name
  value
  result
}
    `;
export const CertificateOfOriginDocument = gql`
    query CertificateOfOrigin($productNumber: String!, $lotNumber: String!, $brand: String, $documentType: String) {
  getCertificateOfOrigin(input: {productNumber: $productNumber, lotNumber: $lotNumber, brand: $brand, documentType: $documentType}) {
    ... on CertificateUrl {
      url
    }
    ... on CertificateText {
      lotNumber
      signatureUrl
      testSpecifications {
        ...CertificateOfOriginTestSpecifications
      }
      product {
        ...CertificateOfOriginProduct
      }
    }
  }
}
    ${CertificateOfOriginTestSpecificationsFragmentDoc}
${CertificateOfOriginProductFragmentDoc}`;
export type CertificateOfOriginComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CertificateOfOriginQuery, CertificateOfOriginQueryVariables>, 'query'> & ({ variables: CertificateOfOriginQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CertificateOfOriginComponent = (props: CertificateOfOriginComponentProps) => (
      <ApolloReactComponents.Query<CertificateOfOriginQuery, CertificateOfOriginQueryVariables> query={CertificateOfOriginDocument} {...props} />
    );
    

/**
 * __useCertificateOfOriginQuery__
 *
 * To run a query within a React component, call `useCertificateOfOriginQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificateOfOriginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificateOfOriginQuery({
 *   variables: {
 *      productNumber: // value for 'productNumber'
 *      lotNumber: // value for 'lotNumber'
 *      brand: // value for 'brand'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useCertificateOfOriginQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CertificateOfOriginQuery, CertificateOfOriginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CertificateOfOriginQuery, CertificateOfOriginQueryVariables>(CertificateOfOriginDocument, options);
      }
export function useCertificateOfOriginLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CertificateOfOriginQuery, CertificateOfOriginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CertificateOfOriginQuery, CertificateOfOriginQueryVariables>(CertificateOfOriginDocument, options);
        }
export type CertificateOfOriginQueryHookResult = ReturnType<typeof useCertificateOfOriginQuery>;
export type CertificateOfOriginLazyQueryHookResult = ReturnType<typeof useCertificateOfOriginLazyQuery>;
export type CertificateOfOriginQueryResult = ApolloReactCommon.QueryResult<CertificateOfOriginQuery, CertificateOfOriginQueryVariables>;