import React, { ReactNode, CSSProperties } from 'react'
import clsx from 'clsx'
import { FieldProps, getIn } from 'formik'
import { CertificateSearchType } from '@utils/searchUtils'
import { LiquidInputProps } from '../LiquidInput/LiquidInput'
import LiquidCertificateAutoComplete from '../LiquidCertificateAutoComplete'
import LiquidFormLabel from '../LiquidFormLabel'
import LiquidFormHelpText from '../LiquidFormHelpText'

interface LiquidInputAdapterProps extends FieldProps, LiquidInputProps {
  rootClass?: string
  rootStyle?: CSSProperties
  required?: boolean
  help?: ReactNode
  type: CertificateSearchType
  placeholder: string
  brand?: string
  lotNumber?: string
  productNumber?: string
  label?: ReactNode
}

const LiquidCertificateAutoCompleteAdapter: React.FC<
  LiquidInputAdapterProps
> = ({
  rootClass,
  rootStyle,
  size = 'medium',
  field,
  form,
  help,
  type,
  placeholder,
  brand,
  productNumber,
  lotNumber,
  label,
  onChange,
  ...otherProps
}) => {
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const hasError = error && touched

  return (
    <div
      className={clsx({
        [String(rootClass)]: rootClass,
      })}
      style={rootStyle}
    >
      {label && (
        <LiquidFormLabel htmlFor={`${type}-input`} size={size} error={hasError}>
          {label}
        </LiquidFormLabel>
      )}
      <LiquidCertificateAutoComplete
        id={type}
        {...field}
        onChange={(value) => {
          if (onChange) onChange(value)
          form.setFieldValue(field.name, value.toUpperCase())
        }}
        type={type}
        placeholder={placeholder}
        brand={brand}
        productNumber={productNumber}
        lotNumber={lotNumber}
        error={Boolean(hasError)}
        aria-describedby={(hasError || help) && `${type}-help-text`}
        {...otherProps}
      />
      {(hasError || help) && (
        <LiquidFormHelpText id={`${type}-help-text`} error={Boolean(hasError)}>
          {hasError ? error : help ? help : null}
        </LiquidFormHelpText>
      )}
    </div>
  )
}

export default LiquidCertificateAutoCompleteAdapter
