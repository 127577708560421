import React from 'react'
import clsx from 'clsx'
import { CatalogImage as CatalogImageType } from '@src/types/graphql-types'
import { makeStyles } from '@material-ui/core/styles'

const NO_IMAGE_FILE_SQ = '/assets/images/pdp-no-image-sq/pdp-no-image-sq.png'

const useStyles = makeStyles(() => ({
  image: { maxHeight: '100%', maxWidth: '100%', width: '100%' },
}))

interface CatalogImageProps {
  image?:
    | { altText?: string; mediumUrl?: string }
    | Pick<CatalogImageType, 'altText' | 'mediumUrl'>
  altTextFallback?: string
  squareImageFallback?: boolean
  className?: string
  width?: string
  height?: string
}

// @TODO extend the `DynamicProduct` fragment and others to include small/large URL and
// add srcSet to image component
const CatalogImage: React.FC<CatalogImageProps> = ({
  image,
  altTextFallback = '',
  className,
  width,
  height,
}) => {
  const styles = useStyles()
  return (
    <img
      className={clsx(styles.image, className)}
      src={image?.mediumUrl || NO_IMAGE_FILE_SQ}
      alt={image?.altText || altTextFallback}
      data-testid="active-image"
      id="active-image"
      width={width}
      height={height}
    />
  )
}

export default CatalogImage
