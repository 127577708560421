import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Theme } from '@material-ui/core'

import LinkStyleButton, {
  LinkStyleButtonSizes,
} from '@src/components/LinkStyleButton'
import messages from '@utils/messages'
import howToMessages from '@utils/MessageSets/howToMessages'
import ResponsiveModal, { ModalSizes } from '../ResponsiveModal'
import { sendProductDetailsEvent } from '@src/utils/analytics'
import {
  ProductDetailsEventCategory as eventCategory,
  ProductDetailsEventAction as eventAction,
} from '@utils/analytics/enums'
import { CertificateType } from '@src/types/graphql-types'
import LotNumberInstructions from './LotNumberInstructions'
import NotFoundSection from './NotFoundSection'

export const useStyles = makeStyles((theme: Theme) => ({
  modalTrigger: {
    fontSize: theme.typography.pxToRem(16),
    cursor: 'pointer',
  },
  modalBody: {
    flex: 1,
    overflowY: 'scroll',
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 8, 12),
    },
  },
}))

interface LotNumberInstructionsDialogProps {
  type: CertificateType
  productNumber?: string
}

const LotNumberInstructionsDialog: React.FC<
  LotNumberInstructionsDialogProps
> = ({ type, productNumber }) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const [showModal, setShowModal] = useState(false)
  const typeLowercase = String(type).toLowerCase()
  const typeUppercase = String(type).toUpperCase()

  const buttonMessageText = messages[typeUppercase]
    ? `(${formatMessage(messages[typeUppercase])})`
    : ''
  const howToInstructionsMessage =
    howToMessages[`HOW_TO_ENTER_${typeUppercase}`] ||
    howToMessages.HOW_TO_UNKNOWN

  useEffect(() => {
    if (type === CertificateType.Coo && showModal) {
      sendProductDetailsEvent(
        eventAction.HowToEnterLotCoo,
        eventCategory.ProductDetailsPage,
        productNumber
      )
    }
  }, [showModal])

  return (
    <>
      <LinkStyleButton
        size={LinkStyleButtonSizes.Large}
        onClick={() => setShowModal(true)}
      >
        {formatMessage({
          id: 'HOW_TO_ENTER_LOT_NUMBER',
          defaultMessage: 'How to enter Lot Number',
        })}{' '}
        {buttonMessageText}
      </LinkStyleButton>
      {showModal ? (
        <ResponsiveModal
          open
          size={ModalSizes.Large}
          onClose={() => setShowModal(false)}
          aria-labelledby={`how-to-enter-${typeLowercase}`}
          renderTitle={() => (
            <Typography component="h2" variant="h2">
              <FormattedMessage {...howToInstructionsMessage} />
            </Typography>
          )}
        >
          <div className={classes.modalBody}>
            <LotNumberInstructions type={type} isDialog />
            <NotFoundSection type={type} isDialog />
          </div>
        </ResponsiveModal>
      ) : null}
    </>
  )
}

export default LotNumberInstructionsDialog
