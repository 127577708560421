import { Typography } from '@material-ui/core'
import messages from '@src/utils/messages'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ResponsiveModal, {
  ResponsiveModalBody,
  ModalSizes,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import { support } from '@src/routes'
import { CertificateType } from '@src/types/graphql-types'
import { makeStyles } from '@material-ui/core/styles'
import { Linq } from '../Linq'

interface CertificateNotFoundModalProps {
  certificateType: CertificateType
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles((theme) => ({
  paragraphSeparation: {
    marginBottom: theme.spacing(4),
  },
}))

const CertficateNotFoundModal: React.FC<CertificateNotFoundModalProps> = ({
  certificateType,
  open,
  onClose,
  children,
}) => {
  const classes = useStyles()
  return (
    <ResponsiveModal
      open={open}
      onClose={onClose}
      size={ModalSizes.Small}
      renderTitle={() => (
        <Typography variant="h2">
          <FormattedMessage
            {...messages.CERTIFICATE_TYPE_NOT_FOUND}
            values={{ certificateType }}
          />
        </Typography>
      )}
    >
      <ResponsiveModalBody>
        <Typography variant="body2" className={classes.paragraphSeparation}>
          <FormattedMessage {...messages.UNABLE_TO_FIND_CERTIFICATE} />
        </Typography>
        {children}
        <Typography variant="body2">
          <FormattedMessage
            id="NO_PRODUCT_DOC_ONLINE"
            defaultMessage="In some cases, our product documentation may not be available online. Please {contactUs} to request a copy."
            values={{
              contactUs: (
                <Linq href={support.customerSupport()}>
                  <FormattedMessage
                    id="CONTACT_US_LOWERCASE"
                    defaultMessage="contact us"
                  />
                </Linq>
              ),
            }}
          />
        </Typography>
      </ResponsiveModalBody>
    </ResponsiveModal>
  )
}

export default CertficateNotFoundModal
