import { Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

export const useStyles = makeStyles((theme: Theme) => ({
  bannerContainer: {
    padding: theme.spacing(6, 0),
    borderTop: `1px solid ${theme.palette.grey[400]}`,
  },
  banner: {
    padding: theme.spacing(6),
    alignItems: 'start',
    background: theme.palette.background.grey,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& p': {
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(15),
      },
    },
  },
  sampleCoa: {
    paddingBottom: theme.spacing(2),
  },
  downloadCoa: {
    paddingTop: theme.spacing(4),
  },
  bodyText: {
    marginBottom: theme.spacing(2),
  },
}))

interface NeedSampleCOAProps {
  children: React.ReactNode
}

const NeedSampleCOA: React.FC<NeedSampleCOAProps> = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.bannerContainer}>
      <div className={classes.banner}>
        <Typography variant="h3" component="h3" className={classes.sampleCoa}>
          <FormattedMessage
            id="NEED_SAMPLE_COA"
            defaultMessage="Need A Sample COA?"
          />
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          <FormattedMessage
            id="SAMPLE_COA_MESSAGE"
            defaultMessage="This is a sample Certificate of Analysis (COA) and may not represent a recently manufactured lot of this specific product."
          />
        </Typography>
        <Typography variant="body1" className={classes.downloadCoa}>
          {children}
        </Typography>
      </div>
    </div>
  )
}
export default NeedSampleCOA
