import React, { useState } from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { ApolloClient } from 'apollo-boost'
import { Theme, Typography, Button, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isNil } from 'lodash'

import { SampleCertificateOfAnalysisQuery } from '@src/queries/SampleCertificateOfAnalysisQuery.generated'

import { UserSession } from '@utils/userSession'
import { useUserSession } from '@src/utils/useUserSession'
import ResponsiveModal, { ModalSizes } from '@src/components/ResponsiveModal'
import {
  GlobalSnackbarState,
  useGlobalSnackbar,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { CertificateFormProps, showCertificate } from './CertificateForm'
import { CertificateType } from '@src/types/graphql-types'
import { sendProductDetailsEvent } from '@src/utils/analytics'
import {
  ProductDetailsEventCategory as eventCategory,
  ProductDetailsEventAction as eventAction,
} from '@utils/analytics/enums'
import CertficateNotFoundModal from '@src/components/CertificateNotFound/CertificateNotFound'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    fontWeight: 900,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoIcon: {
    position: 'relative',
    top: 4,
    left: 8,
    cursor: 'pointer',
  },
  modalBodyContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },
    height: 150,
  },
  modalHeading: {
    opacity: 0.64,
  },
  btn: {
    whiteSpace: 'nowrap',
  },
  coaInNotFoundModal: {
    marginBottom: theme.spacing(4),
  },
}))

export enum SampleCoaLinkType {
  button = 'button',
  text = 'text',
}

interface ViewSampleCoaProps {
  linkType: SampleCoaLinkType
  props: CertificateFormProps
  data: SampleCertificateOfAnalysisQuery | undefined
  client: ApolloClient<object>
}

const ViewSampleCoa: React.FC<ViewSampleCoaProps> = ({
  linkType,
  props,
  data,
  client,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const { userSession } = useUserSession()
  const [showSampleCoaInfo, setShowSampleCoaInfo] = useState(false)
  const { setGlobalSnackbarState } = useGlobalSnackbar()
  const [certificateNotFoundModal, setCertificateNotFoundModal] =
    useState<CertificateType | null>(null)

  const handleGetSampleCertificate = (
    client: ApolloClient<object>,
    props: CertificateFormProps,
    intl: IntlShape,
    setGlobalSnackbarState: React.Dispatch<
      React.SetStateAction<GlobalSnackbarState>
    >,
    data: SampleCertificateOfAnalysisQuery | undefined,
    userSession: UserSession,
    setCertificateNotFoundModal: React.Dispatch<
      React.SetStateAction<CertificateType | null>
    >
  ) => {
    showCertificate(
      client,
      'getSampleCertificate',
      intl,
      setGlobalSnackbarState,
      true,
      data,
      null,
      userSession,
      props,
      setCertificateNotFoundModal
    )
  }

  const clickHandler = (client: ApolloClient<object>) => {
    handleGetSampleCertificate(
      client,
      props,
      intl,
      setGlobalSnackbarState,
      data,
      userSession,
      setCertificateNotFoundModal
    )
  }

  return (
    <>
      {linkType === SampleCoaLinkType.button && (
        <Button
          data-testid={'view_sample_coa'}
          id={'view_sample_coa'}
          type="submit"
          variant="outlined"
          color="primary"
          size="large"
          className={classes.btn}
          onClick={() => {
            sendProductDetailsEvent(
              eventAction.ViewSampleCoa,
              eventCategory.ProductDetailsPage,
              props.productNumber
            )
            clickHandler(client)
          }}
          aria-label="Submit"
        >
          {intl.formatMessage({
            id: 'VIEW_SAMPLE_COA',
            defaultMessage: 'View Sample COA',
          })}
        </Button>
      )}

      {linkType === SampleCoaLinkType.text && (
        <Typography variant="body2" className={classes.coaInNotFoundModal}>
          <Link
            onClick={() => {
              sendProductDetailsEvent(
                eventAction.ViewSampleCoa,
                eventCategory.ProductDetailsPage,
                props.productNumber
              )
              sendProductDetailsEvent(
                eventAction.ViewSampleCOAError,
                eventCategory.ProductDetailsPage,
                props.productNumber
              )
              clickHandler(client)
            }}
            style={{ cursor: 'pointer' }}
          >
            <a>
              <FormattedMessage
                id="VIEW_A_SAMPLE_CERTIFICATE_OF_ANALYSIS_(COA)"
                defaultMessage="View a sample Certificate of Analysis (COA)"
              />
            </a>
          </Link>
          <span>{'. '}</span>
          <FormattedMessage
            id="MAY_NOT_REPRESENT_LOT"
            defaultMessage="It may not represent a recently manufactured lot of this specific product."
          />
        </Typography>
      )}

      {showSampleCoaInfo && (
        <ResponsiveModal
          open={showSampleCoaInfo}
          onClose={() => setShowSampleCoaInfo(false)}
          size={ModalSizes.Small}
          renderTitle={() => (
            <Typography
              variant="h2"
              component="h2"
              className={classes.modalHeading}
            >
              <FormattedMessage
                id="SAMPLE_COA_HEADER"
                defaultMessage="SAMPLE COA"
              />
            </Typography>
          )}
        >
          <div className={classes.modalBodyContainer}>
            <FormattedMessage
              id="SAMPLE_COA_INFO"
              defaultMessage="This is a sample Certificate of Analysis (COA) and may not represent a recently manufactured lot of this specific product."
            />
          </div>
        </ResponsiveModal>
      )}
      <CertficateNotFoundModal
        certificateType={props.type}
        open={!isNil(certificateNotFoundModal)}
        onClose={() => setCertificateNotFoundModal(null)}
      />
    </>
  )
}

export default ViewSampleCoa
